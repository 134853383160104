<template>
  <div style="width: 100vw; height: 100vh; background: #fff; color: #000;">
    <!--  -->
    <div
      v-if="cannotGetPackage === false"
      class="center"
      style="width: 400px; height: 500px; background: #e6e7e8; margin: 0 auto"
    >
      <v-img src="DeliverTransferBG.jpg"> </v-img>
      <div style="position: absolute; top: 0px; width: 100%; height: 89%">
        <div
          id="downloadButtonBg"
          @click="downloadPackage"
          style="
            z-index: 10;
            width: 300px;
            height: 70px;
            border-radius: 100px;
            cursor: pointer;
            position: absolute;
            bottom: 5px;
            left: 54px;
            -webkit-box-shadow: -9px 10px 24px -10px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: -9px 10px 24px -10px rgba(0, 0, 0, 0.75);
            box-shadow: -9px 10px 24px -10px rgba(0, 0, 0, 0.75);
          "
        >
          <div
            style="
              position: absolute;
              width: 277px;
              height: 70px;
              text-align: left;
              left: 0px;
              display: grid;
              place-items: center;
            "
          >
            <v-avatar size="60" style="position: absolute; left: 6px">
              <img :src="campaignLogoUrl" />
            </v-avatar>
            <div style="position: absolute; left: 75px; width: 201px">
              <p
                style="
                  margin-bottom: 5px;
                  font-family: NotoSans;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ packageInfo.package.filename }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Link Expired -->
    <div v-else style="top:50%; position:relative;">
      <div style="text-align: center; font-family: NotoSans; font-size: 30px;">
        <span>The download link has expired.</span>
      </div>
    </div>
  </div>
</template>

<script>
import MediaTypeController from "@/services/controllers/MediaType";

export default {
  created() {
    //Get Campaign
    this.getPackageInfo();
  },

  data: () => ({
    packageInfo: null,
    campaignLogoUrl: "d-clamp-holder.jpg",
    cannotGetPackage: false,
  }),

  methods: {
    getPackageInfo() {
      MediaTypeController.getPackageDetail(this.$route.query.id)
        .then((res) => {
          this.cannotGetPackage = false;
          this.campaignLogoUrl = res.data.campaign.logo
            ? res.data.campaign.logo.urlThumb
            : "d-clamp-holder.jpg";
          this.packageInfo = res.data;
        })
        .catch(() => {
          this.cannotGetPackage = true;
        });
    },

    downloadPackage() {
      const link = document.createElement("a");
      link.href =
        process.env.VUE_APP_API_URL +
        "/media/download/" +
        this.$route.query.id;

      // link.download = label
      link.click();
    },
  },
};
</script>

<style scoped>
  body {
    background: #4ba6d5;
  }

  .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  #downloadButtonBg:hover {
    background: #4aa6d5;
  }

  #downloadButtonBg {
    background: white;
  }
</style>
